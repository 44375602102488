import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Helmet from 'react-helmet';

const Tracking = () => {
  const { trackPageView } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);
  return (
    <>
      <Helmet>
        <script
          async
          defer
          data-domain="couchsurvey.com"
          src="https://plausible.stackocean.com/js/plausible.js"
        ></script>
      </Helmet>
    </>
  );
};

export default Tracking;
